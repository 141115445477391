<template>
  <div class="main-page">
    <Scan @change="scanGetProduct"/>
    <div class="smp-card">
      <div class="smp-cell hover" @click="showProPopup">
        <div class="smp-cell-main">
          <div class="title">选择产品</div>
          <div class="action">
            <span :class="{'info': product}">{{product ? product.category + ((product.title == '型号暂未填写' || !product.title) ? '' : ' ' + product.title ) : '请选择所需要服务的产品' }}</span>
            <van-icon name="arrow" color="#9C9DAA" size="2.6667vw"/>
          </div>
        </div>
      </div>
      <div class="smp-cell-body mini van-hairline--top">
        <div class="info-tips">
          <van-icon name="info-o" color="#FF954B" size="3.2vw"/>
          <span>温馨提示： KDS服务只针对油烟机安装前预约，可视厨房装修需要，提前预埋烟管或厨房布局建议。</span>
        </div>
      </div>
      <div class="smp-cell">
        <div class="smp-cell-main">
          <div class="title">物流状态</div>
          <div class="action">
            <div class="action-btn flex-center" :class="{ 'active': logStatus === 1 }"
              @click="logStatus = 1">
              <span>已到货</span>
            </div>
            <div class="action-btn flex-center" :class="{ 'active': logStatus === 2 }"
              @click="logStatus = 2">
              <span>未到货</span>
            </div>
          </div>
        </div>
      </div>
      <div class="smp-cell-body mini van-hairline--top" style="padding-bottom:4vw" v-if="logStatus==2">
        <div class="info-tips">
          <van-icon name="info-o" color="#FF954B" size="3.2vw"/>
          <span>温馨提示： KDS服务会收取50-100元押金，烟机安装时退还，涉及辅材会收取费用哦。</span>
        </div>
      </div>
      <div class="smp-footer-btn" :class="{'active': product && logStatus != -1}" @click="addToProductList">
        <div class="smp-footer-btn-main flex-center van-hairline--top">
          <i class="icon-plus">*</i>
          <span>确认添加</span>
        </div>
      </div>
    </div>
    <div class="smp-card" v-if="productList.length > 0">
      <div class="smp-cell hover">
        <div class="smp-cell-main">
          <div class="title">已添加服务产品</div>
        </div>
      </div>
      <div class="smp-cell-body van-hairline--top" v-for="(item, index) in productList" :key="index">
        <div class="pro-info">
          <div class="photo">
            <img :src="item.photo" class="contain-img" alt="">
          </div>
          <div class="info">
            <div class="title van-ellipsis">{{ item.category }}</div>
            <div class="desc van-ellipsis">{{ item.title || '型号暂未填写' }}</div>
            <div class="tag">物流状态：{{ item.logStatus == 1 ? '已到货' : '未到货' }}</div>
          </div>
          <div class="remove-btn" @click="onRemovePro(index)">
            <div class="icon-remove"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="smp-card">
      <div class="smp-cell">
        <div class="smp-cell-main">
          <div class="title">服务备注</div>
        </div>
      </div>
      <div class="smp-cell-body van-hairline--top">
        <div class="textarea-box">
          <textarea class="textarea" v-model="remark" rows="5" maxlength="100" placeholder="如有服务问题可进行说明，方便服务人员进行上门服务。（非必选）"></textarea>
          <div class="text-length">{{ remark.length }}/100</div>
        </div>
      </div>
    </div>
    <div class="smp-card">
      <div class="smp-cell hover" @click="timeVisible=true">
        <div class="smp-cell-main">
          <div class="title">服务时间</div>
          <div class="action">
            <span :class="{'info': time}">{{ timeStr || '请选择上门服务时间'}}</span>
            <van-icon name="arrow" color="#9C9DAA" size="3vw"/>
          </div>
        </div>
      </div>
      <div class="smp-cell hover" v-if="!addressInfo">
        <div class="smp-cell-main van-hairline--top" @click="addrVisible=true">
          <div class="title">选择上门地址</div>
          <div class="action">
            <span>请选择您的上门地址</span>
            <van-icon name="arrow" color="#9C9DAA" size="3vw"/>
          </div>
        </div>
      </div>
      <div class="smp-cell hover" @click="addrVisible=true" v-else>
        <div class="smp-cell-addr van-hairline--top">
          <div class="addr-icon">
            <img src="~@/assets/images/icon/icon_location.svg" width="100%" alt="">
          </div>
          <div class="addr-info">
            <div class="title">
              <div class="username">{{ addressInfo.username }}</div>
              <div class="mobile">{{ addressInfo.mobile }}</div>
            </div>
            <div class="address">{{ addressInfo.province + addressInfo.city + addressInfo.area + addressInfo.address }}</div>
          </div>
          <div class="action">
            <van-icon name="arrow" color="#9C9DAA" size="3vw"/>
          </div>
        </div>
      </div>
    </div>
    <div class="smp-footer">
      <router-link to="/charging" class="link">收费标准</router-link>
      <div class="line"></div>
      <router-link to="/guarantee" class="link">服务保修</router-link>
    </div>
    <div class="ft-placeholder"></div>
    <div class="footer-bar">
      <van-button class="footer-btn" type="info" @click="onSubmit" 
        :disabled="productList.length == 0 || !time || !addressInfo"
        :loading="loading"
      >立即提交</van-button>
    </div>
    <!-- 选择产品弹窗 -->
    <ProductFilter 
      :list="proList"
      :visible="proVisible" @close="proVisible=false" 
      @submit="choicePorduct" 
      @create="addProVisible=true;proVisible=false" />

    <!-- 新增产品弹窗 -->
    <AddProduct v-if="categoryList.length > 0" :list="categoryList" :visible="addProVisible" 
      @close="addProVisible=false" @submit="addProductSuccess" 
      @back="closeAddproPopup" />

    <!-- 选择服务时间弹窗 -->
    <TimePopup :visible="timeVisible" @close="timeVisible=false" @submit="choiceTimeSuccess" />

    <!-- 选择服务地址弹窗 -->
    <AddressPopup 
      :list="addrList" 
      :visible="addrVisible" 
      @close="addrVisible=false" 
      @submit="choiceAddressSuccess" 
      @change="changeAddressSuccess" />
  </div>
</template>

<script>
  import { Icon, Dialog, Button, Toast } from 'vant'
  import Scan from '../components/scan'
  import ProductFilter from '../components/product'
  import TimePopup from '../components/serviceTime'
  import AddressPopup from '../components/address'
  import AddProduct from '../components/addProduct'
  import { getBusinessCategoryList, createKDSOrder, addMyProduct } from '@/api/service'
  import { getUserAddressList, getMyProList } from '@/api/common'

  export default {
    components: {
      [Icon.name]: Icon,
      [Button.name]: Button, 
      ProductFilter,
      TimePopup,
      AddressPopup,
      AddProduct,
      Scan
    },
    data() {
      return {
        remark: '',
        disabled: false,
        proVisible: false,
        timeVisible: false,
        addrVisible: false,
        addProVisible: false,
        logStatus: -1,
        addrList: [],
        proList: [],
        categoryList: [],
        productList: [],
        product: null,
        loading: false,
        addressInfo: null,
        time: null,
        timeStr: '',
        tipsLabel: '',
        hasProList: false,
      }
    },
    created() {
      this.getProCategoryList()
      this.getAddrList()
      // this.getProductList()
    },
    methods: {
      getAddrList() {
        getUserAddressList().then(res => {
          this.addrList = res.data.list
        })
      },
      getProductList() {
        getMyProList({category:'KDS'}).then(res => {
          this.proList = res.data.list
        })
      },
      getProCategoryList() {
        getBusinessCategoryList(
          {title: 'KDS', type: 1}
        ).then(res => {
          this.categoryList = res.data.list
          const cate = this.categoryList.map(el => {
            return el.title
          })
          this.tipsLabel = cate.join(',')
        })
      },
      scanGetProduct(e) {
        const index = this.categoryList.findIndex(x => x.title == e.category)
        if (index < 0) {
          Dialog.alert({
            title: '提示',
            message: `暂不支持该品类产品`,
            confirmButtonColor: '#186AFF'
          })
          return
        }

        const toast = Toast.loading({
          message: '添加中...',
          forbidClick: true,
        });
        const params = {
          category: e.category,
          title: e.title
        }
        addMyProduct(params).then(res => {
          Toast.success('添加成功')
          const result = {
            ...e,
            id: res.data.id
          }
          this.product = result
          toast.clear()
        }).catch(() => {
          toast.clear()
        })
      },
      showProPopup() {
        if (!this.hasProList) {
          const toast = Toast.loading({
            message: '加载中...',
            forbidClick: true,
          });
          getMyProList({category:'KDS', is_buy: 0}).then(res => {
            this.proList = res.data.list
            this.hasProList = true
            toast.clear()
            if (this.proList.length === 0) {
              this.addProVisible = true
            } else {
              this.proVisible = true
            }
          })
        } else {
          if (this.proList.length === 0) {
            this.addProVisible = true
          } else {
            this.proVisible = true
          }
        }
      },
      closeAddproPopup() {
        if (this.proList.length === 0) {
          this.addProVisible = false
        } else {
          this.addProVisible = false
          this.proVisible = true
        }
      },
      onRemovePro(index) {
        Dialog.confirm({
          title: '移除产品',
          message: '是否移除该已添加的服务产品？',
          confirmButtonColor: '#186AFF'
        })
        .then(() => {
          this.productList.splice(index, 1)
        })
        .catch(() => {
          // on cancel
        });
      },
      /**
       * @description: 选中商品
       * @param {*}
       * @return {*}
       */      
      choicePorduct(result) {
        this.product = result
        this.proVisible = false
      },
      /**
       * @description: 添加商品成功, 重新获取我的商品列表
       */      
      addProductSuccess(result) {
        this.product = result
        // this.getProductList()
        this.addProVisible = false
      },
       /**
       * @description: 选择时间
       */      
      choiceTimeSuccess(result) {
        this.time = result.date + ' ' + result.time
        this.timeStr = result.date + '(周' + result.day + ') ' + result.time
        this.timeVisible = false
      },
      /**
       * @description: 添加地址成功
       */      
      choiceAddressSuccess(result) {
        this.addressInfo = result
        this.addrVisible = false
      },
      /**
       * @description: 保存修改地址成功
       */
      changeAddressSuccess(result) {
        this.addressInfo = result
        this.addrVisible = false
        this.getAddrList()
      },
      /**
       * @description: 添加产品到列表
       */  
      addToProductList() {
        if (!this.product) {
          Toast('请选择需要服务的产品')
          return
        }
        if (this.logStatus === -1) {
          Toast('请选择物流状态')
          return
        }
        const product = {
          category: this.product.category,
          title: this.product.title,
          id: this.product.id,
          logStatus: this.logStatus,
          photo: this.product.photo
        }
        this.productList.push(product)
        this.product = null
        this.logStatus = -1
      },   
      /**
       * 提交
       */
      onSubmit() {
        if (this.loading == true) {
          return
        }
        const product_list = this.productList.map(el => {
          return {
            product_id: el.id,
            logistics_status: el.logStatus
          }
        })
        const params = {
          product_list,
          remark: this.remark,
          appoint_time: this.time, 
          address_id: this.addressInfo.id
        }
        this.loading = true
        createKDSOrder(params).then(() => {
          // Dialog.alert({
          //   title: '提交成功',
          // }).then(() => {
          //   this.loading = false
          //   this.$router.push('/order/submit')
          // });
          this.loading = false
          this.$router.replace('/order/result')
        }).catch(() => {
          this.loading = false
        })
      }
    },
  }
</script>

<style lang="less" scoped>
  @import '../style.less';
</style>